import React from "react";
import TextButton from "@components/TextButton";
import useLanguage from "@src/hooks/useLanguage";

const HelpButton = () => {
  const dictionary = useLanguage();
  return (
    <TextButton
      onClick={() =>
        window.location.replace("http://t.me/kasanie_info")
      }
    >
      {dictionary.help}
    </TextButton>
  );
};
export default HelpButton;
