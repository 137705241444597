import styled from "@emotion/styled";
import React, { useState } from "react";
import Input from "@components/Input";
import Button from "@components/Button";
import { isValidPhoneNumber } from "react-phone-number-input";
import Loading from "@components/Loading";
import useLanguage from "@src/hooks/useLanguage";

interface IProps {
  phone: string;
  setPhone: (e: string) => void;
  processSignIn: () => void;
  title: string;
  subtitle: string;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-family: var(--common-font);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
`;

const Subtitle = styled.div`
  font-family: var(--common-font);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
`;
const SendSmsPhone: React.FC<IProps> = ({
  phone,
  setPhone,
  processSignIn,
  title,
  subtitle,
}) => {
  const [isPhoneValid, setPhoneValid] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const dictionary = useLanguage();
  const validatePhone = () => {
    const isValid = isValidPhoneNumber(phone);
    setPhoneValid(isValid);
    return isValid;
  };
  const handleAuth = () => {
    if (validatePhone()) {
      setLoading(true);
      processSignIn();
    }
  };
  return (
    <Root>
      <Title style={{ paddingBottom: 14 }}>{title}</Title>
      <Subtitle style={{ paddingBottom: 30 }}>{subtitle}</Subtitle>
      <Input
        inputType="phone"
        error={!isPhoneValid ? dictionary.wrongPhoneFormat : undefined}
        value={phone}
        onChange={setPhone}
        onBlur={validatePhone}
        onFocus={() => setPhoneValid(true)}
      />
      <Button style={{ marginTop: 64 }} onClick={handleAuth}>
        {loading ? (
          <Loading color="#ffffff" style={{ maxHeight: 19 }} fontSize="5px" />
        ) : (
          title
        )}
      </Button>
    </Root>
  );
};
export default SendSmsPhone;
