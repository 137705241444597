import styled from "@emotion/styled";
import React from "react";
import RightArrow from "@components/RigthArrow";

interface IProps {
  text: string;
  icon?: string;
  onClick: () => void;
}

const Root = styled.div`
  display: flex;
  background: #f3f4f5;
  border-radius: 4px;
  margin-top: 20px;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  position: relative;
  flex-direction: row;
  padding: 8px 12px;
  width: 100%;
  outline: none;
  cursor: pointer;
`;
const Text = styled.div`
  font-family: var(--common-font);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  display: flex;
  color: #545961;
`;
const DirectBtn: React.FC<IProps> = ({ text, icon, onClick }) => {
  return (
    <Root onClick={onClick}>
      <Text>{text}</Text>
      <RightArrow />
    </Root>
  );
};
export default DirectBtn;
