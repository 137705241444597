import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import Contacts from "@src/screens/card/Contacts";
import WorkInfo from "@src/screens/card/WorkInfo";
import { ICard } from "@src/models";
import { layoutStyle } from "@components/Layout";
import SaveEditBtn from "@src/screens/card/SaveEditBtn";
import DirectMode from "@src/screens/card/DirectMode";
import PaymentTab from "./PaymentTab";
import HelpButton from "@components/HelpButton";

interface IProps {
  card: ICard;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  ${layoutStyle};
  margin-bottom: 20px;
`;
const Description = styled.div`
  font-family: var(--common-font);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #787878;
  padding: 25px 0;
  white-space: pre-wrap;
  word-break: break-all;
`;
const SecondaryInfo: React.FC<IProps> = ({ card }) => {
  const [rootHeight, setRootHeight] = useState<number>(0);
  useEffect(() => {
    setRootHeight(document.getElementById("root")!.offsetHeight);
  }, []);
  const win = window.innerHeight;
  return (
    <Root>
      <DirectMode />
      <Contacts phones={card.phones} emails={card.emails} />
      <PaymentTab paymentLinks={card.payments} />
      <WorkInfo
        workCompany={card.workCompany}
        position={card.position}
        workPhone={card.workPhone}
        workAddress={card.workAddress}
        workEmail={card.workEmail}
        workSite={card.workSite}
        linkedIn={card.linkedIn}
      />
      {card.description && <Description>{card.description}</Description>}
      {win < rootHeight && <SaveEditBtn />}
      <HelpButton />
    </Root>
  );
};
export default SecondaryInfo;
