import styled from "@emotion/styled";
import React, { createRef } from "react";
import { ReactComponent as Close } from "@src/assets/CloseWhite.svg";
import { layoutStyle } from "@components/Layout";

interface IProps {
  props?: string;
}

const Root = styled.div<{ color?: string }>`
  display: flex;
  background: ${({ color }) => (color != null ? color : "#000000")};
  align-items: center;
  width: 100%;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  transition: height 0.5s;
  height: 48px;
`;
const Layout = styled.div`
  display: flex;
  ${layoutStyle};
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
const Text = styled.div`
  font-family: var(--common-font);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;

  align-items: center;
  text-align: center;
  color: #ffffff;
`;

const StoreLink: React.FC<IProps> = ({ props }) => {
  if (props == null) return null;
  const ref = createRef<HTMLDivElement>();
  const object = JSON.parse(props);
  return (
    <Root ref={ref} color={object.color}>
      <Layout>
        <a style={{ textDecoration: "none" }} href={object?.link}>
          <Text>{object.text}</Text>
        </a>
        <Close
          style={{ transition: "2s", padding: 5 }}
          onClick={(e) => {
            e.preventDefault();
            ref.current!.style.height = "0px";
            ref.current!.style.minHeight = "0px";
          }}
        />
      </Layout>
    </Root>
  );
};
export default StoreLink;
