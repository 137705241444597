import styled from "@emotion/styled";

const Title = styled.div`
  font-family: var(--common-font);
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
`;

export default Title;
