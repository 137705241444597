import styled from "@emotion/styled";
import React from "react";
import { ReactComponent as Plus } from "@src/assets/smallPlus.svg";

interface IProps {
  onClick: () => void;
}

interface IPlusProps {
  color?: string;
}

const Root = styled.div`
  display: flex;
  font-family: var(--common-font);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  padding: 19px 0 23px;
  cursor: pointer;
`;

const IconRoot = styled.div`
  margin-right: 8px;
  height: 24px;
  width: 24px;
`;

const PlusIcon: React.FC<IPlusProps> = ({color = 'var(--accent-color)'}) => {
  return (
    <IconRoot>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M3.43597 5.18404C3 6.03969 3 7.15979 3 9.4V14.6C3 16.8402 3 17.9603 3.43597 18.816C3.81947 19.5686 4.43139 20.1805 5.18404 20.564C6.03969 21 7.15979 21 9.4 21H14.6C16.8402 21 17.9603 21 18.816 20.564C19.5686 20.1805 20.1805 19.5686 20.564 18.816C21 17.9603 21 16.8402 21 14.6V9.4C21 7.15979 21 6.03969 20.564 5.18404C20.1805 4.43139 19.5686 3.81947 18.816 3.43597C17.9603 3 16.8402 3 14.6 3H9.4C7.15979 3 6.03969 3 5.18404 3.43597C4.43139 3.81947 3.81947 4.43139 3.43597 5.18404ZM13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8V11H8C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H11V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V13H16C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11H13V8Z"
            fill={color} />
      </svg>
    </IconRoot>
  )
}

const AddButton: React.FC<IProps> = ({ onClick, children }) => {
  return (
    <Root onClick={onClick}>
      <PlusIcon />
      {children}
    </Root>
  );
};
export default AddButton;
