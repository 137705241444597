export const lng = {
  RU: {
    kasanie: "касание",
    edit: "Редактировать",
    register: "Регистрация",
    toRegister: "Зарегистритовать",
    welcome: "Добро пожаловать!",
    registerToActivate: "Зарегистрируйся, чтобы активировать NFC-метку.",
    policyRegister:
      "* Регистрируясь, Вы принимаете правила политики конфиденциальности",
    sameWayToAuthForLogin:
      "Используйте тот же метод авторизации, что и при регистрации вашей метки",
    loginApple: "Войдите с Apple",
    loginGoogle: "Войдите с Google",
    loginEmail: "Войдите по почте",
    loginPhone: "Войдите с телефоном",
    registerApple: "Регистрация с Apple",
    registerGoogle: "Регистрация с Google",
    registerEmail: "Регистрация по почте",
    registerPhone: "Регистрация с телефоном",
    login: "Вход",
    logout: "Выйти",
    authToEdit: "Авторизуйтесь в сервисе, чтобы редактировать данные.",
    next: "Дальше",
    help: "Нужна помощь?",
    smsCode: "Kод из смс",
    newSmsCode: "Новый код через",
    mainInfoTab: {
      fillInfo: "Заполните профиль",
      fillInfoToDisplay: "Укажите данные, которые будут отображаться в визитке",
      addPhone: "Добавить номер телефона",
      addEmail: "Добавить почту",
    },
    usernameTab: {
      whatIsUserName: "Username нужен для укорочения ссылки на касание",
      allowedSymbols: "Допустимые символы a - z, 0 - 9 и “_”",
    },
    socialMediaTab: {
      title: "Социальные сети",
      edit: "Изменить",
      addLink: "Добавить сылку",
      chooseSocial:
        "Выберите социальную сеть и добавьте ссылку на свой профиль",
      chooseMoney:
        "Выберите тип денежных переводов и добавьте ссылку на сервис",
    },
    moneyTab: {
      title: "Денежные переводы",
      addLink: "Добавить сылку",
      chooseMoneyAndAdd:
        "Выберите тип денежных переводов и добавьте ссылку на сервис",
    },
    workTab: {
      title: "О работе",
    },
    textTab: {
      title: "Поле для любого текста",
      whatTextFor:
        "Вы можете написать, что хотите. Это будет отображаться в вашей визитке",
    },
    deleteAccTab: {
      title: "Удаление аккаунта",
      description:
        "Вы можете безвозвратно удалить аккаунт чтобы обнулить касание",
      phrases: {
        text1: "Удалить профиль",
        text2: "Вы уверены?",
        text3: "Точно-точно?",
        text4: "Это же навсегда!",
        text5: "Ну ты че, нормально же общались",
        text6: "Да, я хочу удалить профиль",
      },
    },
    directModeTab: {
      title: "Direct mode",
      on: "включен",
      off: "выключен",
      description:
        "Direct mode дает возможность поделиться конкретной социальной сетью при вашем касании",
      chooseLink: "Выберите ссылку, на которую будет указывать Direct mode",
      turnOn: "Включить Direct mode",
      turnOff: "Выключить Direct mode",
      addLinkToUseDirectMode:
        "Чтобы выбрать ссылку, сначала добавьте её в список социальных сетей",
    },
    placeholders: {
      name: "Имя",
      lastName: "Фамилия",
      middleName: "Отчество",
      phone: "Телефон",
      email: "Почта",
      username: "Username",
      companyName: "Название компании",
      position: "Должность",
      workPhone: "Рабочий телефон",
      workEmail: "Рабочая почта",
      address: "Адрес",
      workSite: "Рабочий сайт",
      anyText: "Любой текст",
    },
    add: "Добавить",
    save: "Сохранить",
    goToStore: "Нажмите сюда для перехода в магазин",
    saveToPhone: "Сохранить в контакты",
    contacts: "Контакты",
    phone: "Телефон",
    email: "Почта",
    job: "Работа",
    company: "Компания",
    position: "Должность",
    wrongEmail: "Некорректный email",
    wrongPhoneFormat: "Некорректный формат телефона",
    hasBeenSent: "Сообщение отправлено",
    anotherWayToRegister:
      "Если смс не приходит, то попробуйте другой метод авторизации",
    codeShouldContain6Numbers: "Код должен состоять из 6-ти цифр",
    wrongSmsCode: "Введен неверный код.",
    tagIsNotRegistered: "Эта  NFC-метка еще не зарегистрирована",
    wrongUsername: "Некорректное имя пользователя",
    cantBeLonger20: "Имя не может быть больше 20 символов",
    cantBeLessThan4: "Минимальная длина имени - 4 символа",
    usernameIsTaken: "Это имя пользователя уже занято",
    seeMore: "Показать все",
    hide: "Скрыть",
    shareYourCard: "Поделись своей визиткой",
    linkName: "Название вашей ссылки",
    invalidLink: "Ссылка не работает",
    wentWrong: "Something went wrong....",
  },
  EN: {
    kasanie: "kasanie",
    edit: "Edit",
    welcome: "Welcome!",
    register: "Registration",
    registerToActivate: "Register to activate NFC tag",
    policyRegister: "* For register you have to accept private policy",
    sameWayToAuthForLogin:
      "Please use same authentication method as for registration",
    loginApple: "Log in with Apple",
    loginGoogle: "Log in with Google",
    loginEmail: "Log in with email",
    loginPhone: "Log in with phone",
    login: "Log in",
    logout: "Log out",
    registerApple: "Register with Apple",
    registerGoogle: "Register with Google",
    registerEmail: "Register with email",
    registerPhone: "Register with phone",
    authToEdit: "Log in to edit data",
    next: "Next",
    shareYourCard: "Share your card",
    help: "Need some help?",
    smsCode: "Sms code",
    newSmsCode: "New sms code in",
    mainInfoTab: {
      fillInfo: "Fill profile info",
      fillInfoToDisplay: "Enter data which will be displayed in your card",
      addPhone: "Add phone number",
      addEmail: "Add email",
    },
    usernameTab: {
      whatIsUserName: "Username is making link to your profile shorter",
      allowedSymbols: "allowed symbols a - z, 0 - 9 и “_”",
    },
    workTab: {
      title: "Work info",
    },
    socialMediaTab: {
      title: "Social medias",
      edit: "Change",
      addLink: "Add link",
      chooseSocial: "Choose social media and add link to your profile",
    },
    moneyTab: {
      title: "Money transfer",
      chooseMoneyAndAdd: "Choose transfer service and add link",
    },
    textTab: {
      title: "Field for any text",
      whatTextFor:
        "You can type what ever you want. It will be displayed in you card",
    },
    deleteAccTab: {
      title: "Delete account",
      description: "You can delete your account and reset card",
      phrases: {
        text1: "Delete",
        text2: "Are you sure?",
        text3: "Really?",
        text4: "It's permanently",
        text5: "Come on!",
        text6: "Yes, I want to delete my profile",
      },
    },
    directModeTab: {
      title: "Direct mode",
      on: "is on",
      off: "is off",
      description:
        "Direct mode gives opportunity to share particular link directly without showing additional info",
      chooseLink: "Choose link witch lead from you account",
      turnOn: "Turn Direct mode on",
      turnOff: "Turn Direct mode off",
      addLinkToUseDirectMode: "To use direct more please add some links",
    },
    placeholders: {
      name: "Name",
      lastName: "Last name",
      middleName: "Middle name",
      phone: "Phone",
      email: "Email",
      username: "Username",
      companyName: "Company",
      position: "Position",
      workPhone: "Work phone",
      workEmail: "Work email",
      address: "Address",
      workSite: "Work site",
      anyText: "Any text",
    },
    save: "Save",
    add: "Add",
    goToStore: "Press here to go to store",
    saveToPhone: "Save contact",
    contacts: "Contacts",
    phone: "Phone",
    email: "Email",
    job: "Job info",
    company: "Company",
    position: "Position",
    sendLetterEmailToVerify: "Letter has been sent to you email address",
    wrongEmail: "Wrong email",
    hasBeenSent: "Letter has been sent",
    anotherWayToRegister:
      "If there is no code please try another authentication method",
    codeShouldContain6Numbers: "Code must contain 6 numbers",
    wrongSmsCode: "You have entered wrong code",
    tagIsNotRegistered: "This NFC tag is not registered",
    toRegister: "Register",
    wrongPhoneFormat: "Wrong phone format",
    wrongUsername: "Not correct username",
    cantBeLonger20: "Сan't be longer than 20 symbols",
    cantBeLessThan4: "Сan't be longer than 20 symbols",
    usernameIsTaken: "This username is already taken",
    seeMore: "See more",
    hide: "Hide",
    linkName: "Link name",
    invalidLink: "Invalid link",
    wentWrong: "Что-то пошло не так....",
  },
};
