import styled from "@emotion/styled";

const TextButton = styled.div`
  cursor: pointer;
  font-family: var(--common-font);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--accent-color);
  text-align: center;
  padding-top: 15px;
`;

export default TextButton;
