import styled from "@emotion/styled";
import React from "react";
import { SOCIAL_ICONS } from "@src/assets/socialIconsConfig";

interface IProps {
  type: string;
  displayName: string;
  onClick: () => void;
  textColor?: string;
}

const Root = styled.div`
  display: flex;
  width: 100%;
  min-height: 48px;
  border-radius: 4px;
  border: 1px solid #f3f4f5;
  align-items: center;
  margin: 5px 0;
  box-sizing: border-box;
  cursor: pointer;
  & > * {
    padding-left: 10px;
  }
`;
const Text = styled.div<{ color?: string }>`
  font-family: var(--common-font);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ color }) => color ?? "#545961"};
`;
const Icon = styled.img`
  width: 30px;
  height: 30px;
`;
const LinkOutput: React.FC<IProps> = ({
  type,
  displayName,
  onClick,
  textColor,
}) => {
  return (
    <Root onClick={onClick}>
      <Icon src={SOCIAL_ICONS[type]} alt="icon" />
      <Text color={textColor}>{displayName}</Text>
    </Root>
  );
};
export default LinkOutput;
